import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';
import { SettingsService } from '../../../settings/settings.service';
import * as moment from 'moment';
import { Login } from '../login/login.model';
import { interval, throwError } from 'rxjs';
import * as CryptoJS from 'crypto-js';
import { HubTeleatendimentoService } from 'src/app/shared/hub-teleatendimento.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  intervalo = interval(1800000);
  iniciarRefreshToken = this.intervalo.subscribe(() => this.getRefreshToken());

  constructor(
    private http: HttpClient,
    private settingsService: SettingsService,
    private hubTeleatendimento: HubTeleatendimentoService
  ) {
  }

  login(request: Login) {
    const httpOptions: { headers: any; observe: any; } = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      observe: 'response'
    };

    const data = {
      customerKey: environment.xCustomerKey,
      applicationId: 2,
      login: request.login,
      password: request.senha,
      deviceInfo: 'Portal'
    }

    return this.http.post(`${environment.baseServiceUrl}auth`, data, httpOptions)
      .pipe(
        switchMap(async response => {
          return response;
        }),
        shareReplay()
      );
  }

  async logout() {
    await this.clearSession();
    await this.hubTeleatendimento.clearSessionHubTeleatendimento();
  }

  public async setSession(response: any, preserveData?: boolean) {
    if (!preserveData)
      await this.clearSession();
    if ((response.status === 200) && (response.body !== null) && (response.body.authorizationToken !== '')) {
      this.settingsService.set(environment.production ? 'key1' : 'secretKey', response.body.secretKey);
      this.settingsService.set(environment.production ? 'key2' : 'expiresAt', this.encryptData(response.body.expiresAt));
      this.settingsService.set(environment.production ? 'key3' : 'authorizationToken', this.encryptData(response.body.authorizationToken));
    }
  }

  private async clearSession() {
    this.settingsService.unset(environment.production ? 'key1' : 'secretKey');
    this.settingsService.unset(environment.production ? 'key2' : 'expiresAt');
    this.settingsService.unset(environment.production ? 'key3' : 'authorizationToken');
    this.settingsService.unset(environment.production ? 'key4' : 'cpfUsuario');
    this.settingsService.unset(environment.production ? 'key5' : 'codigoUsuarioSaude');
    this.settingsService.unset(environment.production ? 'key6' : 'usuarioSelecionado');
    this.settingsService.unset(environment.production ? 'key7' : 'codigoUsuarioSaudeUsuarioSelecionado');
    this.settingsService.unset(environment.production ? 'key8' : 'cpfUsuarioSelecionado');
  }

  async checkLoggedIn() {
    const expiresAt = await this.getExpirationDate();
    const isLoggedIn = expiresAt != null ? moment().isBefore(expiresAt) : false;
    return isLoggedIn;
  }

  async getAuthorizationToken() {
    const authorizationToken = this.decryptData(this.settingsService.get(environment.production ? 'key3' : 'authorizationToken'));
    if (authorizationToken == null) {
      return '';
    }
    return authorizationToken;
  }

  async getExpirationDate() {
    let expiresAt = this.decryptData(this.settingsService.get(environment.production ? 'key2' : 'expiresAt'));
    if (expiresAt == null) {
      return null;
    }
    return moment(expiresAt);
  }

  async getRefreshToken() {
    if (await this.checkLoggedIn()) {
      const httpOptions: { headers: any; observe: any; } = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
        observe: 'response'
      };

      return this.http.get(`${environment.baseServiceUrl}auth/refresh-token`, httpOptions)
        .pipe(
          catchError(error => {
            return throwError(() => new Error(error));
          }))
        .subscribe(response => {
          return this.setSession(response, true);
        });
    } else {
      this.iniciarRefreshToken.unsubscribe();
      return;
    }
  }

  encryptData(data: any) {
    if (environment.production) {
      try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), this.settingsService.get(environment.production ? 'key1' : 'secretKey')).toString();
      }
      catch (e) {
        return console.log(e);
      }
    }
    else {
      return data;
    }
  }

  decryptData(data: any) {
    if (environment.production) {
      try {
        const bytes = CryptoJS.AES.decrypt(data, this.settingsService.get(environment.production ? 'key1' : 'secretKey'));
        if (bytes.toString())
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

        return data;
      }
      catch (e) {
        return console.log(e);
      }
    }
    else {
      return data;
    }
  }
}
