import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private isLoading: BehaviorSubject<boolean>;
  filaLoading: number = 0;
  constructor() {
    this.isLoading = new BehaviorSubject<boolean>(false);
  }

  startLoading() {
    ++this.filaLoading;
    if (this.isLoading.getValue() === false) {
      this.isLoading.next(true);
    }
  }

  stopLoading(force?: boolean) {
    if (force || this.filaLoading < 0) {
      this.filaLoading = 0;
    }
    else {
      if (this.filaLoading > 0) {
        this.filaLoading--;
      }
    }

    if (this.filaLoading == 0 && this.isLoading.getValue()) {
      this.isLoading.next(false)
    }
  }

  public IsLoading(): Observable<boolean> {
    return this.isLoading.asObservable();
  }
}
