import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SettingsService } from 'src/settings/settings.service';

@Injectable()

export class Helper {

  iconOlho = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAYAAADhAJiYAAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB7klEQVRYw+2YP0tcQRTFz65xFVJZpBBS2O2qVSrRUkwqYfUDpBbWQu3ELt/HLRQ/Q8RCGxVJrRDEwj9sTATxZ/Hugo4zL/NmV1xhD9xi59177pl9986fVwLUSyi/tYC+oL6gbuNDYtyUpLqkaUmfJY3a+G9JZ5J2JW1J2ivMDBSxeWCfeBxYTHSOWMcRYLOAEBebxtEVQWPASQdi2jgxro4E1YDTQIJjYM18hszGbew4EHNq/kmCvgDnHtI7YBko58SWgSXg1hN/btyFBM0AlwExczG1YDZrMS4uLUeUoDmgFfjLGwXEtG05wNXyTc4NXgzMCOAIGHD8q0ATuDZrempkwGJ9+AfUQ4K+A/eEseqZ/UbgdUw4fqs5vPeW+5mgBvBAPkLd8cPju+341P7D/WAaJGCdOFQI14kr6o/zvBKZYz11L5Okv5KGA89Kzu9K0b0s5ZXt5PjuOL6TRV5ZalFP4F+rrnhZ1Cs5vN6ijmn7Q162/ThZq9+YNW3MbfvDAOed5cxdGL+RFaUPKQtjI8DVAr66/u9i6+jJzTXm+HFEVqxVYBD4SNZNKzk109HxoycPaG0bIeugVDTp4hH2qdXJDu6xOAAWiuQoQdLHhvY1aEZSVdInG7+Q9EvSz9RrUKqgV0PP3Vz7gvqCOsUj+CxC9LB1Dc8AAAASdEVYdEVYSUY6T3JpZW50YXRpb24AMYRY7O8AAAAASUVORK5CYII=';

  constructor(
    private settingsService: SettingsService
    ) { }

  public dataAtualAnoMesDia() {
    let resultado = new Date();
    let dia = String(resultado.getDate()).padStart(2, '0');
    let mes = String(resultado.getMonth() + 1).padStart(2, '0');
    let ano = resultado.getFullYear();
    let hora = String('00');
    let minutos = String('00');
    let segundos = String('00');
    let dataAtualFormatada =
      ano + '-' + mes + '-' + dia + 'T' + hora + ':' + minutos + ':' + segundos;
    return dataAtualFormatada;
  }

  public removerHora(resultado: any) {
    let newDate = new Date(resultado);
    let dia = String(newDate.getDate()).padStart(2, '0');
    let mes = String(newDate.getMonth() + 1).padStart(2, '0');
    let ano = newDate.getFullYear();
    let hora = String('00');
    let minutos = String('00');
    let segundos = String('00');
    let datConsulta =
      ano + '-' + mes + '-' + dia + 'T' + hora + ':' + minutos + ':' + segundos;
    return datConsulta;
  }

  comparaSenhaValidator(otherControlName: string) {

    let thisControl: FormControl;
    let otherControl: FormControl;

    return function comparaSenhaValidator(control: FormControl) {

      if (!control.parent) {
        return null;
      }

      // Initializing the validator.
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }

      return null;

    }
  }

  toBool(data: any) {
    return (data == 1 || data == '1' || data == true || data == 'true') ? true : false;
  }
}
