import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AtualizarDependente } from './atualizar-dependente.model';
import { ListarDependente } from './listar-dependente.model';
import { PesquisaDependente } from './pesquisa-dependente.model';

@Injectable({
  providedIn: 'root'
})
export class DependenteService {

  constructor(private http: HttpClient) {}
  pesquisarDependente(request: PesquisaDependente) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/pesquisar-dependente`, request);
  }

  adicionarDependente(request: AtualizarDependente) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/adicionar-dependente`, request);
  }

  removerDependente(request: AtualizarDependente) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/remover-dependente`, request);
  }
  
  listarDependentes(request: ListarDependente) {
    return this.http.post(`${environment.baseServiceUrl}usuarios/listar-dependentes`, request);
  }
}

