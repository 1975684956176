import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { getPermissoesCliente, PermissaoState } from 'src/app/seguranca/cliente/state/permissao.reducer';

@Component({
  selector: 'app-barra-pesquisa',
  templateUrl: './barra-pesquisa.component.html',
  styleUrls: ['./barra-pesquisa.component.scss'],
})
export class BarraPesquisaComponent implements OnInit, OnDestroy {
  @Input() placeholder = '';
  @Output() notificarPesquisa = new EventEmitter<boolean>();
  faMagnifyingGlass = faMagnifyingGlass;
  textoPesquisado: string = '';

  permissoesSubscription: Subscription;

  constructor(
    private permissaoStore: Store<PermissaoState>,
  ) {
    this.permissoesSubscription = this.permissaoStore.select(getPermissoesCliente).subscribe(permissoes => {
    });
  }

  ngOnInit(): void {}

  armazenarTextoPesquisado(event: KeyboardEvent) {
    this.textoPesquisado = (<HTMLInputElement>event.target).value;
  }

  pesquisa(event: KeyboardEvent) {
    let houvePesquisa = Boolean(event);
    this.notificarPesquisa.emit(houvePesquisa);
  }

  ngOnDestroy(): void {
    this.permissoesSubscription.unsubscribe();
  }
}
