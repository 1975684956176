import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { MessageService } from 'src/app/shared/message.service';
import { AuthService } from '../auth/auth.service';
import { PermissaoState } from '../cliente/state/permissao.reducer';

@Injectable({
  providedIn: 'root'
})
export class PermissoesClienteGuard implements CanActivate {

  constructor(
    private router: Router,
    private messageService: MessageService,
    private authService: AuthService,
    private permissaoStore: Store<PermissaoState>,
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return true;
    // switch (state.url) {
    //   case '/noticias':
    //     if (Boolean(this.authService.permissoes.IndNoticias) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/minha-saude':
    //     if (Boolean(this.authService.permissoes.IndMinhaSaude) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/upas':
    //     if (Boolean(this.authService.permissoes.IndUsoUpa) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/unidades-saude-basica':
    //     if (Boolean(this.authService.permissoes.IndUnidadesBasicas) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/agendamentos':
    //     if (Boolean(this.authService.permissoes.IndAgendamentos) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/avisos':
    //     if (Boolean(this.authService.permissoes.IndAvisos) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/vacinas':
    //     if (Boolean(this.authService.permissoes.IndVacinas) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/teleatendimentos':
    //     if (Boolean(this.authService.permissoes.IndTeleatendimento) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/teleatendimentos-compartilhado':
    //     if (Boolean(this.authService.permissoes.IndTeleatendimentoCompartilhado) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/documentos':
    //     if (Boolean(this.authService.permissoes.IndDocAssinado) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/servicos':
    //     if (Boolean(this.authService.permissoes.IndServUteis) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/ouvidoria':
    //     if (Boolean(this.authService.permissoes.IndOuvidoria) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/medicamentos':
    //     if (Boolean(this.authService.permissoes.IndMedicamentos) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/perguntas-frequentes':
    //     if (Boolean(this.authService.permissoes.IndFaq) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/cns':
    //     if (Boolean(this.authService.permissoes.IndCns) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   case '/cirurgias':
    //     if (Boolean(this.authService.permissoes.IndCirurgias) == false) {
    //       this.router.navigate(['']);
    //       this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //       return false;
    //     }
    //   default:
    //     this.router.navigate(['']);
    //     this.messageService.showInfo("Você não possui acesso à essa funcionalidade");
    //     return false;
    // }
    // return true;
  }
}
