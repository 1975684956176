import { Component, Inject } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Title } from "@angular/platform-browser";
import { MessageService } from './shared/message.service';
import { Alert } from './shared/alert.model';
import { getPermissoesCliente, PermissaoState } from './seguranca/cliente/state/permissao.reducer';
import { Store } from '@ngrx/store';
import * as ClientActions from './seguranca/cliente/state/permissao.actions'
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  permissoesSubscription: Subscription;

  constructor(
    public messageService: MessageService,
    @Inject(APP_BASE_HREF) private baseHref: string,
    private titleService: Title,
    private permissaoStore: Store<PermissaoState>,
  ) {
    this.permissaoStore.dispatch(ClientActions.carregarPermissoesCliente());
    this.permissoesSubscription = this.permissaoStore.select(getPermissoesCliente).subscribe(permissoes => {this.titleService.setTitle(`Portal do Cidadão - ${permissoes.DscNomeCliente}`);})
  }

  close(alert: Alert) {
    this.messageService.dismiss(alert);
  }
}
