<header
  class="menu-superior d-flex align-items-center justify-content-between position-absolute position-sm-fixed w-100 shadow">
  <div class="d-flex align-items-center">
    <a (click)="cliqueIconeMenu($event)" (click)="fecharOpcoesDoUsuario()">
      <fa-icon [icon]="faBars" class="icone-menu-superior text-white px-2 py-1 m-0 fs-5 me-1"></fa-icon>
    </a>
    <a [routerLink]="['']">
      <img class="logo" src="assets/images/fastmedic.png" alt="logo Fastmedic">
    </a>
  </div>
  <div class="d-flex align-items-center justify-content-center">
    <button class="botao-usuario-selecionado rounded-1 mw-100 d-flex dropdown-toggle" type="button" (click)="exibirOpcoesDoUsuario()">
      <img class="imagem-perfil me-2 rounded-circle" src="assets/images/imagem_perfil.jpg" alt="">
      <p *ngIf="!dispositivoMobile" class="m-0 me-1 text-left fs-5 texto-cinza">{{
        usuarioSelecionado }}</p>
    </button>
    <div *ngIf="opcoesAbertas" class="opcoes-usuario overflow-auto border border-secondary border-opacity-25 d-flex flex-column position-fixed bg-white rounded-1 w-auto h-auto shadow">
      <div *ngIf="indDependentes && mostraDependentes" class="border-bottom border-secondary border-opacity-20">
        <a class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start p-2"
          (click)="mudarUsuarioSelecionado(usuario.nomeUsuarioMobile.toUpperCase(), usuario.codigoUsuarioSaude, usuario.numeroCpf)">
          <span class=" me-1 badge text-white" [class]="usuarioSelecionado.toUpperCase() == usuario.nomeUsuarioMobile.toUpperCase() ? 'bg-usuario-selecionado' : 'bg-usuario'">Titular</span>
          <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">{{ usuario.nomeUsuarioMobile.toUpperCase() }}</p>
        </a>
        <a *ngFor="let dependente of dependentes"
          class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start p-2"
          (click)="mudarUsuarioSelecionado(dependente.nomeUsuario.toUpperCase(), dependente.codigoUsuarioSaude, dependente.cpfDependente)">
          <span class="me-1 badge text-white" [class]="usuarioSelecionado.toUpperCase() == dependente.nomeUsuario.toUpperCase() ? 'bg-usuario-selecionado' : 'bg-usuario'">Dependente</span>
          <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">{{ dependente.nomeUsuario.toUpperCase() }}</p>
        </a>
      </div>
      <a class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start py-1 px-2"
        [routerLink]="['meu-perfil']">
        <fa-icon [icon]="faUser" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Meu Perfil</p>
      </a>
      <a *ngIf="indDependentes"
        class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start py-1 px-2"
        [routerLink]="['dependentes']">
        <fa-icon [icon]="faUsers" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Dependentes</p>
      </a>
      <a class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start py-1 px-2"
        [routerLink]="['alterar-senha']">
        <fa-icon [icon]="faLockKeyhole" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Alterar Senha</p>
      </a>
      <a class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start py-1 px-2"
        (click)="abrirModal(modal)">
        <fa-icon [icon]="faTrashCan" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Excluir Conta</p>
      </a>
      <a class="opcao border-bottom border-secondary border-opacity-10 d-flex align-items-center justify-content-start py-1 px-2"
        [routerLink]="['termos-de-uso']">
        <fa-icon [icon]="faFileLines" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Termos de Uso</p>
      </a>
      <a class="opcao border-bottom border-secondary border-opacity-20 d-flex align-items-center justify-content-start py-1 px-2"
        [routerLink]="['sobre']">
        <fa-icon [icon]="faInfoSquare" class="m-0 p-1 fs-5 text-dark"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Sobre</p>
      </a>
      <a class="opcao d-flex align-items-center justify-content-start py-1 px-2"
        (click)="deslogar()">
        <fa-icon class="m-0 p-1 fs-5 text-dark" [icon]="faArrowRightFromBracket"></fa-icon>
        <p class="my-0 me-0 ms-1 text-dark fs-5 text-left">Sair</p>
      </a>
    </div>
  </div>
</header>
<div class="altura-menu-superior"></div>
<!-- modal excluir conta -->
<ng-template #modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmação de exclusão de conta</h4>
    <span type="button" class="text-dark px-2 destaque" (click)="modal.close()">X</span>
  </div>
  <div class="modal-body py-5">
    <form class="w-100 text-center d-flex flex-column" [formGroup]="form">
      <p class="m-0">Informe sua senha para prosseguir com a exclusão de conta:</p>
      <!-- campo senha -->
      <div class="input-group d-flex flex-column">
        <div class="input-group-prepend d-flex mt-4">
          <input type="password" class="form-control rounded" formControlName="senha" placeholder="Senha">
        </div>
        <div *ngIf="form.controls['senha'].invalid && (form.controls['senha'].dirty || form.controls['senha'].touched)"
          class="validacao text-start py-1 ps-2 fs-6">
          <i *ngIf="form.controls['senha'].errors?.['required']">*Conteúdo é obrigatório</i>
          <i *ngIf="form.controls['senha'].errors?.['maxlength']">*A senha deve conter no máximo 15 caracteres</i>
          <i *ngIf="form.controls['senha'].errors?.['minlength']">*A senha deve conter no mínimo 4 caracteres</i>
        </div>
      </div>
      <!-- campo confirmar senha -->
      <div class="input-group d-flex flex-column">
        <div class="input-group-prepend d-flex mt-2">
          <input type="password" class="form-control rounded" formControlName="confirmarSenha"
            placeholder="Confirmar Senha">
        </div>
        <div
          *ngIf="form.controls['confirmarSenha'].invalid && (form.controls['confirmarSenha'].dirty || form.controls['confirmarSenha'].touched)"
          class="validacao text-start py-1 ps-2 fs-6">
          <i *ngIf="form.controls['confirmarSenha'].errors?.['required']">*Conteúdo é obrigatório</i>
          <i *ngIf="!form.controls['confirmarSenha'].errors?.['required']">*Senha inválida, a confirmação de senha deve
            ser idêntica a senha</i>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="bg-vermelho" (click)="verificarFormulario()">Excluir Conta</button>
    <button type="button" (click)="modal.close()">Cancelar</button>
  </div>
</ng-template>