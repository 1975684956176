import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Observable } from 'rxjs';

@Component({
  selector: 'app-layout-padrao',
  templateUrl: './layout-padrao.component.html',
})
export class LayoutPadraoComponent implements OnInit {
  larguraTela =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;
  resizeObservable: Observable<Event> = fromEvent(window, 'resize');
  verificarResize: any;
  menuAberto = this.larguraTela >= 576;

  constructor() {
  }

  ngOnInit(): void {
    this.verificarResize = this.resizeObservable.subscribe((evt) => {
      this.larguraTela =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      this.menuAberto = this.larguraTela >= 576;
    });
  }

  exibirMenu() {
    this.menuAberto = !this.menuAberto;
  }

  fecharMenu() {
    if (this.larguraTela < 576) {
      this.menuAberto = this.larguraTela >= 576;
    }
  }

  onActivate(event: ActivatedRoute) {
    window.scroll(0, 0);
  }

  ngOnDestroy() {
    this.verificarResize.unsubscribe();
  }
}
